export default [
  {
    id: 1,
    name: "FIFISH V6 Underwater Drone",
  },
  {
    id: 2,
    name: "FIFISH V6 Industrial Case",
  },
  {
    id: 3,
    name: "FIFISH V6s Industrial Case",
  },
  {
    id: 4,
    name: "FIFISH Thruster Protector",
  },
  {
    id: 5,
    name: "FIFISH HDMI Box",
  },
  {
    id: 6,
    name: "FIFISH V6s Underwater Drone",
  },
  {
    id: 7,
    name: "EcoFlow RIVER PRO EXTRA BATTERY",
  },
  {
    id: 8,
    name: "EcoFlow RIVER PRO EU",
  },
  {
    id: 9,
    name: "EcoFlow RIVER EXTRA BATTERY",
  },
  {
    id: 10,
    name: "EcoFlow RIVER MAX EU",
  },
  {
    id: 11,
    name: "EcoFlow RIVER EU",
  },
  {
    id: 12,
    name: "Ecoflow 110W Solar Panel",
  },
  {
    id: 13,
    name: "Ecoflow 160W Solar Panel",
  },
  {
    id: 14,
    name: "Ecoflow 400W Solar Panel",
  },
  {
    id: 15,
    name: "EcoFlow DELTA Mini EU",
  },
  {
    id: 16,
    name: "Ecoflow MC4 Solar Extension",
  },
  {
    id: 17,
    name: "Ecoflow Solar MC4 Parallel Connection Cable",
  },
  {
    id: 18,
    name: "Ecoflow Suction cups",
  },
  {
    id: 19,
    name: "Ecoflow MC4 to XT60 Solar Cable 3.5m",
  },
  {
    id: 20,
    name: "Ecoflow MC4 to XT60 Solar Cable 5m",
  },
  {
    id: 21,
    name: "Ecoflow Car Charge XT60 Cable",
  },
  {
    id: 22,
    name: "Ecoflow AC Cable EU",
  },
  {
    id: 23,
    name: "Ecoflow RIVER Bag",
  },
  {
    id: 24,
    name: "MicaSense RedEdge-MX Kit",
  },
  {
    id: 25,
    name: "MicaSense Altum Kit",
  },
  {
    id: 26,
    name: "MicaSense RedEdge-MX Kit + DJI Skyport",
  },
  {
    id: 27,
    name: "MicaSense Altum Kit + DJI Skyport",
  },
  {
    id: 28,
    name: "MicaSense Dual Camera, Full Kit",
  },
  {
    id: 29,
    name: "MicaSense DLS 2",
  },
  {
    id: 30,
    name: "MicaSense Two 60cm DLS2 cables for RedEdge-MX",
  },
  {
    id: 31,
    name: "MicaSense Dual Camera Kit for Current RedEdge-MX",
  },
  {
    id: 32,
    name: "MicaSense RedEdge-MX Blue Kit (Standalone)",
  },
  {
    id: 33,
    name: "MicaSense RedEdge-MX Blue Kit + DJI Skyport",
  },
  {
    id: 34,
    name: "MicaSense Calibrated Reflectance Panel (CRP)",
  },
  {
    id: 35,
    name: "MicaSense RedEdge-MX Wire Integration Kit",
  },
  {
    id: 36,
    name: "MicaSense RedEdge-MX Lens Cover",
  },
  {
    id: 37,
    name: "MicaSense RE-3 Wire Integration Kit",
  },
  {
    id: 38,
    name: "MicaSense Two 60cm DLS2 cables for Altum RedEdge-MX with SkyPort",
  },
  {
    id: 39,
    name: "MicaSense Altum Wire Integration Kit",
  },
  {
    id: 40,
    name: "MicaSense Altum Lens Cover",
  },
  {
    id: 41,
    name: "MicaSense RE-M Wire Integration Kit",
  },
  {
    id: 42,
    name: "DJI agras AC cable",
  },
  {
    id: 43,
    name: "Dji Agras T30",
  },
  {
    id: 44,
    name: "Dji Agras T10",
  },
  {
    id: 45,
    name: "DJI agras Smart Controller 3.0",
  },
  {
    id: 46,
    name: "Pix4Dmatic",
  },
  {
    id: 47,
    name: "Pix4Dmapper",
  },
  {
    id: 48,
    name: "Pix4Dfields",
  },
  {
    id: 49,
    name: "Pix4Dbim",
  },
  {
    id: 50,
    name: "Pix4Dreact",
  },
  {
    id: 51,
    name: "Pix4Dsurvey",
  },
  {
    id: 52,
    name: "Pix4Dcloude",
  },
  {
    id: 53,
    name: "Pix4Dmatic",
  },
  {
    id: 54,
    name: "Parrot ANAFI USA",
  },
  {
    id: 55,
    name: "Parrot ANAFI USA S.E.",
  },
  {
    id: 56,
    name: "Parrot ANAFI USA GOV",
  },
  {
    id: 57,
    name: "Parrot ANAFI SE",
  },
  {
    id: 58,
    name: "Parrot ANAFI WORK",
  },
  {
    id: 59,
    name: "Parrot SEQUOIA",
  },
  {
    id: 60,
    name: "Matrice 100",
  },
  {
    id: 61,
    name: "MATRICE 100 PART20 Motor Kit",
  },
  {
    id: 62,
    name: "MATRICE 100 PART21 ESC Kit",
  },
  {
    id: 63,
    name: "Matrice 100-PART12-Frame Arm M1",
  },
  {
    id: 64,
    name: "Matrice 100-PART13-Frame Arm M2",
  },
  {
    id: 65,
    name: "Matrice 100-PART14-Frame Arm M3",
  },
  {
    id: 66,
    name: "Matrice 100-PART15-Frame Arm M4",
  },
  {
    id: 67,
    name: "Matrice 100-PART23-Antenna Kit",
  },
  {
    id: 68,
    name: "Matrice 100-PART27-GPS",
  },
  {
    id: 69,
    name: "MATRICE 100-PART33-TB47D Battery (order @ 2)",
  },
  {
    id: 70,
    name: "MATRICE 100-PART34-TB48D Battery (order @ 4)",
  },
  {
    id: 71,
    name: "MATRICE 200 SERIES V2 GPS KIT",
  },
  {
    id: 72,
    name: "MATRICE 200 SERIES V2 MANIFOLD 2 Mounting Bracket",
  },
  {
    id: 73,
    name: "MATRICE 200 SERIES V2-PART02-Cendence S Remote Controller",
  },
  {
    id: 74,
    name: "MATRICE 200 SERIES V2-PART06-SINGLE UPWARD GIMBAL CONNECTOR?RH?",
  },
  {
    id: 75,
    name: "MATRICE 200-PART03-TB55 Intelligent Flight Battery(4)",
  },
  {
    id: 76,
    name: "MATRICE 200-PART16-M200 Series Expansion Antenna Mounting Bracket Set",
  },
  {
    id: 77,
    name: "MATRICE 210 V2 SINGLE UPWARD GIMBAL CONNECTOR",
  },
  {
    id: 78,
    name: "MATRICE 300 RTK (care included)",
  },
  {
    id: 79,
    name: "MATRICE 300 SERIES-PART02-DJI Smart Controller Enterprise?NA&EU&JP&CHT?",
  },
  {
    id: 80,
    name: "MATRICE 300 SERIES-PART06-BS60 Intelligent Battery Station?Universal Edition?",
  },
  {
    id: 81,
    name: "MATRICE 300 SERIES-PART08-TB60 Intelligent Flight Battery",
  },
  {
    id: 82,
    name: "MATRICE 300 SERIES-PART09-DJI Smart Controller Enterprise Monitor Mounting Kit",
  },
  {
    id: 83,
    name: "MATRICE 300 SERIES-PART10-Dual Gimbal Connector",
  },
  {
    id: 84,
    name: "MATRICE 300 SERIES-PART11-Upward Gimbal Connector",
  },
  {
    id: 85,
    name: "MATRICE 300 SERIES-PART13-OSDK Expansion Module",
  },
  {
    id: 86,
    name: "MATRICE 300 SERIES-PART14-2110 Propeller",
  },
  {
    id: 87,
    name: "MATRICE 300 SERIES-PART17-4G Dongle ZTE MF833V",
  },
  {
    id: 88,
    name: "MATRICE 600 Battery Case",
  },
  {
    id: 89,
    name: "MATRICE 600 Intelligent Flight Battery TB47S combo(6PCS)",
  },
  {
    id: 90,
    name: "MATRICE 600 Parallel Multi-Charger (Hex Charger)",
  },
  {
    id: 91,
    name: "MATRICE 600 PRO-PART23-Collapsible GPS Mount",
  },
  {
    id: 92,
    name: "MATRICE 600 PRO-PART26-Foldable Frame Arm Mount Kit",
  },
  {
    id: 93,
    name: "MATRICE 600 PRO-PART30-Landing Leg Kit",
  },
  {
    id: 94,
    name: "MATRICE 600 PRO-PART31-Landing Skid Kit",
  },
  {
    id: 95,
    name: "MATRICE 600 PRO-PART33-Aircraft Arm Kit?M1?",
  },
  {
    id: 96,
    name: "MATRICE 600 PRO-PART34-Aircraft Arm Kit?M2?",
  },
  {
    id: 97,
    name: "MATRICE 600 PRO-PART35-Aircraft Arm Kit?M3?",
  },
  {
    id: 98,
    name: "MATRICE 600 PRO-PART35-Aircraft Arm Kit?M3?",
  },
  {
    id: 99,
    name: "MATRICE 600 PRO-PART36-Aircraft Arm Kit?M4?",
  },
  {
    id: 100,
    name: "MATRICE 600 PRO-PART37-Aircraft Arm Kit?M5?",
  },
  {
    id: 101,
    name: "MATRICE 600 PRO-PART38-Aircraft Arm Kit?M6?",
  },
  {
    id: 102,
    name: "Matrice 600/Pro D-RTK Mounting Bracket",
  },
  {
    id: 103,
    name: "MATRICE 600-PART01-X3/X5/XT/Z3 Gimbal Mounting Bracket",
  },
  {
    id: 104,
    name: "MATRICE 600-PART02-Z15 Gimbal Connector",
  },
  {
    id: 105,
    name: "MATRICE 600-PART03-Power Source Hub Kit",
  },
  {
    id: 106,
    name: "MATRICE 600-PART04-Upper Expansion Bay Kit",
  },
  {
    id: 107,
    name: "MATRICE 600-PART06-D-RTK Mounting Bracket",
  },
  {
    id: 108,
    name: "MATRICE 600-PART07-Aircraft Arm Angle Calibrator",
  },
  {
    id: 109,
    name: "MATRICE 600-PART10-Intelligent Flight Battery TB48S",
  },
  {
    id: 110,
    name: "MATRICE 600-PART11-M600 Black Remote Controller",
  },
  {
    id: 111,
    name: "MATRICE 600-PART15-Remote Controller Channel Expansion Kit",
  },
  {
    id: 112,
    name: "MATRICE 600-PART22-Red Rotatable Clamp Kit",
  },
  {
    id: 113,
    name: "MATRICE 600-PART24-Aircraft Arm Kit?CCW, Red?",
  },
  {
    id: 114,
    name: "MATRICE 600-PART27-Aircraft Arm Kit?CW, Black?",
  },
  {
    id: 115,
    name: "MATRICE 600-PART28-2170R Folding Propeller Kit(CW/CCW)",
  },
  {
    id: 116,
    name: "MATRICE 600-PART30-Red Motor Mount",
  },
  {
    id: 117,
    name: "MATRICE 600-PART31-Black Motor Mount",
  },
  {
    id: 118,
    name: "MATRICE 600-PART34- Aircraft Arm Carbon Tube",
  },
  {
    id: 119,
    name: "MATRICE 600-PART37-Aircraft Arm Oblique Carbon Tube",
  },
  {
    id: 120,
    name: "MATRICE 600-PART39-Landing Gear Bottom Carbon Tube Kit",
  },
  {
    id: 121,
    name: "MATRICE 600-PART41-Landing Gear Teflon Gasket",
  },
  {
    id: 122,
    name: "MATRICE 600-PART45-Screw Kit",
  },
  {
    id: 123,
    name: "MATRICE 600-PART49-M600 Power Distribution Board",
  },
  {
    id: 124,
    name: "MATRICE 600-PART53-Cable Kit",
  },
  {
    id: 125,
    name: "MATRICE 600-PART57-Airframe Arm Fastener",
  },
  {
    id: 126,
    name: "MATRICE 600-PART58-2195 Foldable Propeller(CW/CCW)",
  },
  {
    id: 127,
    name: "M600/M600PRO-PART14-Z30 Gimbal Mounting Kit",
  },
  {
    id: 128,
    name: "Mavic 2 Enterprise Part2 battery",
  },
  {
    id: 129,
    name: "Mavic 2 Enterprise",
  },
  {
    id: 130,
    name: "Mavic 2 Enterprise(DUAL) (Universal Edition)",
  },
  {
    id: 131,
    name: "Mavic 2 Enterprise?ZOOM?Universal Edition (EU)",
  },
  {
    id: 132,
    name: "Mavic 2 Enterprise?DUAL?with Smart Controller (EU)",
  },
  {
    id: 133,
    name: "Mavic 2 Enterprise(ZOOM) (Universal Edition)",
  },
  {
    id: 134,
    name: "Mavic 2 Enterprise?ZOOM? with Smart Controller (EU)",
  },
  {
    id: 135,
    name: "M2EA RTK Module",
  },
  {
    id: 136,
    name: "Mavic 2 Enterprise Advanced (EU)_SP",
  },
  {
    id: 137,
    name: "P4 Multispectral + D-RTK 2 Mobile Station Combo _(EU)",
  },
  {
    id: 138,
    name: "Phantom 4 Multispectral (care included)",
  },
  {
    id: 139,
    name: "Phantom 4 RTK (EU) Combo",
  },
  {
    id: 140,
    name: "Phantom 4 RTK (EU) SDK",
  },
  {
    id: 141,
    name: "Phantom 4 RTK SDK Remote Controller (EU)",
  },
  {
    id: 142,
    name: "P4 RTK (EU) Combo+Ent Shield Basic",
  },
  {
    id: 143,
    name: "Phantom 4 RTK + D-RTK 2 +Ent Shield Basic Combo_EU?",
  },
  {
    id: 144,
    name: "Phantom 4 RTK + D-RTK 2 Mobile Station Combo (EU)",
  },
  {
    id: 145,
    name: "UAV Based Laser Methane Leakage Detector(U10)?oversea version?",
  },
  {
    id: 146,
    name: "wingsland Z15 gimbal Spotlight (Z15)",
  },
  {
    id: 147,
    name: "Zenmuse P1 (EU)_(care included)",
  },
  {
    id: 148,
    name: "DJI Zenmuse  L1 (care included)",
  },
  {
    id: 149,
    name: "Zenmuse H20(EU)_SP",
  },
  {
    id: 150,
    name: "Zenmuse H20T kamera (care included)",
  },
  {
    id: 151,
    name: "Z30",
  },
  {
    id: 152,
    name: "D-RTK 2 BASE STATION TRIPOD",
  },
  {
    id: 153,
    name: "D-RTK 2 High Precision GNSS Mobile Station?JP&KR&EU&UK?",
  },
  {
    id: 154,
    name: "MANIFOLD 2-G 128G(EU)",
  },
  {
    id: 155,
    name: "DJI Terra Pro Overseas Perpetual(1device)",
  },
  {
    id: 156,
    name: "DJI Terra Pro Overseas 1 year(1device)",
  },
  {
    id: 157,
    name: "DJI Terra Electricity Overseas 1 year(1device)",
  },
  {
    id: 158,
    name: "DJI GS Pro License Team Professional (1 Year)",
  },
  {
    id: 159,
    name: "DJI GS Pro Enterprise V1",
  },
  {
    id: 160,
    name: "P4P Part 64 Intelligent Flight Battery",
  },
  {
    id: 161,
    name: "WB37 Intelligent Battery Akkumulátor",
  },
  {
    id: 162,
    name: "P4 low noise propeller part 137",
  },
];
