import React from "react";

const MailIcon = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
    >
      <path
        d="M14 12H2C1.60218 12 1.22064 11.842 0.93934 11.5607C0.658035 11.2794 0.5 10.8978 0.5 10.5V1.43475C0.516824 1.04834 0.682257 0.683369 0.961759 0.416026C1.24126 0.148682 1.61323 -0.000365429 2 6.7284e-07H14C14.3978 6.7284e-07 14.7794 0.158036 15.0607 0.43934C15.342 0.720645 15.5 1.10218 15.5 1.5V10.5C15.5 10.8978 15.342 11.2794 15.0607 11.5607C14.7794 11.842 14.3978 12 14 12ZM2 2.901V10.5H14V2.901L8 6.9L2 2.901ZM2.6 1.5L8 5.1L13.4 1.5H2.6Z"
        fill={color}
      />
    </svg>
  );
};

export default MailIcon;
