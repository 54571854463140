import { dcContent } from "../../services/content";
import {
  Autocomplete,
  Box,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Controller } from "react-hook-form";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import allEquipment from "../../services/content/allEquipment";
import { googleMapsInput } from "../styles/globalStyles";
import React, { useContext } from "react";
import { LocalizationContext } from "../lib/context";
import MailIcon from "@components/Icons/MailIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGoogleMapScript } from "../hooks/useGoogleMapScript";

export default function EditProjectForm({
  handleSubmit,
  handleSave,
  errors,
  control,
  register,
  loading,
  project,
}) {
  const { localeStrings } = useContext(LocalizationContext);
  const formFields = localeStrings.projectDetailsForm.formFields;
  useGoogleMapScript();

  return (
    <form
      onSubmit={handleSubmit(handleSave)}
      style={{ display: "flex", flexDirection: "column", gap: "16px" }}
    >
      {formFields.map((det, i) => {
        const fieldsFromDcCont = dcContent.forms.projectDetails.filter(
          (s) => s.id === det.fieldId
        )[0];
        const formStrings = { ...det, ...fieldsFromDcCont };
        switch (formStrings.formType) {
          case "textField":
            return (
              <FormControl variant="outlined" key={i}>
                <div className="settingsInput">
                  <label className="settings__secondaryText">
                    {formStrings.label}
                  </label>
                  <div className="settingsInput__wrapper">
                    <TextField
                      type="text"
                      className="settingsInput__input"
                      error={!!errors[formStrings.id]}
                      {...register(formStrings.id, {
                        required: {
                          value: formStrings.required,
                          message: formStrings.requiredErrorMessage,
                        },
                      })}
                    />
                  </div>
                </div>
              </FormControl>
              // <Box key={i} sx={{ display: "flex", alignItems: "center" }}>
              //   <TextField
              //     fullWidth
              //     key={i}
              //     type="text"
              //     multiline={formStrings.multiline}
              //     label={formStrings.label}
              //     helperText={errors[formStrings.id]?.message}
              //     error={!!errors[formStrings.id]}
              //     {...register(formStrings.id, {
              //       required: {
              //         value: formStrings.required,
              //         message: formStrings.requiredErrorMessage,
              //       },
              //     })}
              //   />
              // </Box>
            );
          case "places":
            return (
              <Box key={i}>
                <label className="settings__secondaryText">
                  {formStrings.label}
                </label>
                <Box sx={{ width: "100%", mt: "5px" }}>
                  <Controller
                    rules={{
                      required: formStrings.required,
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <GooglePlacesAutocomplete
                          apiKey={process.env.NEXT_PUBLIC_MAPS_API_KEY}
                          selectProps={{
                            defaultInputValue: project?.location,
                            ...field,
                            theme: googleMapsInput.theme,
                            styles: googleMapsInput.styles,
                          }}
                        />
                        {fieldState.error && (
                          <div style={{ color: "red" }}>
                            {formStrings.requiredErrorMessage}
                          </div>
                        )}
                      </>
                    )}
                    onChange={([, data]) => data}
                    name={formStrings.id}
                    control={control}
                  />
                </Box>
              </Box>
            );
          case "select":
            return (
              <Box key={i}>
                <label className="settings__secondaryText">
                  {formStrings.label}
                </label>
                <Controller
                  rules={{
                    required: formStrings.requiredErrorMessage,
                  }}
                  render={({ field: { onChange }, fieldState }) => (
                    <Autocomplete
                      multiple
                      fullWidth
                      defaultValue={project?.skills}
                      options={dcContent.skills.map((skill) => skill.name)}
                      renderInput={(params) => (
                        <TextField
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          {...params}
                          variant="outlined"
                          sx={{
                            "& > div > input": {
                              height: "35px",
                            },
                          }}
                        />
                      )}
                      onChange={(e, data) => onChange(data)}
                      popupIcon={<ExpandMoreIcon />}
                    />
                  )}
                  onChange={([, data]) => data}
                  defaultValue={project?.skills}
                  name={formStrings.id}
                  control={control}
                />
              </Box>
            );
          case "equipment":
            return (
              <Box key={i}>
                <label className="settings__secondaryText">
                  {formStrings.label}
                </label>
                <Controller
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      fullWidth
                      multiple
                      defaultValue={project?.equipment}
                      options={allEquipment.map((eq) => eq.name)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          sx={{
                            "& > div > input": {
                              height: "35px",
                            },
                          }}
                        />
                      )}
                      popupIcon={<ExpandMoreIcon />}
                      onChange={(e, data) => onChange(data)}
                    />
                  )}
                  onChange={([, data]) => data}
                  defaultValue={project?.equipment}
                  name={formStrings.id}
                  control={control}
                />
              </Box>
            );
        }
      })}

      <Box textAlign="right">
        <LoadingButton
          type="submit"
          variant="contained"
          loading={loading}
          sx={{
            p: "1rem 1.6rem",
            backgroundColor: "#2496cc",
            "&:hover": { background: "#2496cc", opacity: 0.9 },
          }}
        >
          {localeStrings.uiGeneral.saveButton}
        </LoadingButton>
      </Box>

      <Box
        sx={{
          width: "110%",
          borderTop: "1px solid #b5b5b5",
          pt: "10px",
          mb: "24px",
          mx: -4,
        }}
      >
        <Typography
          maxWidth="320px"
          color="#B5B5B5"
          fontSize="1rem"
          sx={{ mx: "auto" }}
          textAlign="center"
        >
          Problémába ütköztél az oldalon? Kérdésed van? Vedd fel velünk a
          kapcsolatot! Kapcsolat
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#4d4e4e",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "26px",
            gap: "10px",
            mt: "8px",
          }}
        >
          <MailIcon color="#4D4E4E" />
          Kapcsolat
        </Box>
      </Box>
    </form>
  );
}
