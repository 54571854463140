import { useEffect, useState } from "react";
import {
  addDoc,
  arrayUnion,
  collection,
  deleteField,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../../api_config/firebase-configuration";
import { deleteObject, listAll, ref } from "firebase/storage";
import { Box, Fab, Typography } from "@mui/material";
import ProjectFilesUpload from "./ProjectFilesUpload";
import { getUrl, uploadToFbStorage } from "../../lib/global";
import { containerCard } from "../../styles/globalStyles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useForm } from "react-hook-form";
import EditProjectForm from "../EditProjectForm";
import { dcContent } from "../../../services/content";
import { useUserData } from "../../hooks/useUserData";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export function AddProjectDetails({
  close,
  project = null,
}: {
  close: any;
  project?: any;
}) {
  const { user, userData, actDroner } = useUserData();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...project } });
  const [files, setFiles] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [modImages, setModImages] = useState(false);

  useEffect(() => {
    if (!!project && project.filesUploaded === 0) {
      setModImages(true);
    }
  }, [project]);

  let maxImageNum = 0;

  try {
    if (!!actDroner?.subscriptionLevel)
      maxImageNum = dcContent.pricingLimits.filter(
        (o) => o.priceName === actDroner.subscriptionLevel
      )[0].maxImages;
  } catch (e) {
    console.error("max image error: ", e);
  }

  async function handleSave(data: {
    equipment: string[] | undefined;
    skills: string[] | undefined;
    images: string[];
    location: { label: string };
  }) {
    const updateProjKeepIm = !!project && !modImages;
    const updateProjNewIm = !!project && files.length !== 0;
    if (data.equipment === undefined) {
      data.equipment = [];
    }
    if (data.skills === undefined) {
      data.skills = [];
    }
    if (!project) {
      data.images = [];
    }
    const newImages = [];
    data.location =
      data.location.label === undefined
        ? project.location
        : data.location.label;
    const withinFileLimit =
      maxImageNum === 0 ? true : !(files.length > maxImageNum);
    setLoading(true);
    if (!withinFileLimit) {
      setLoading(false);
      return alert(
        "file limit reached, remove " +
          Math.abs(maxImageNum - files.length) +
          " file(s)"
      );
    } else {
      try {
        const projRef = !project
          ? await addDoc(collection(db, "projects"), {
              ...data,
              username: userData.username,
              email: userData.email,
              filesUploaded: files.length,
            })
          : doc(db, "projects", project.id);

        if (!updateProjKeepIm) {
          try {
            await updateDoc(projRef, {
              images: deleteField(),
            });
          } catch (e) {
            console.error(e);
          }
          try {
            const listRef = ref(storage, `projects/${projRef.id}`);
            const res = await listAll(listRef);
            await Promise.all(
              res.items.map(async (itemRef) => {
                await deleteObject(itemRef);
              })
            );
          } catch (e) {
            console.error(e);
          }
        }
        let counter = 0;

        if (!project && user) {
          await updateDoc(doc(db, "users", user.uid), {
            projects: arrayUnion(projRef.id),
          });
          await updateDoc(doc(db, "usersPublic", user.uid), {
            projects: arrayUnion(projRef.id),
          });
        }
        if (!project || updateProjNewIm) {
          for (const file of files) {
            const url = await uploadToFbStorage(
              file,
              `projects/${projRef.id}/${file.name}`
            );
            newImages.push(url);
            await updateDoc(projRef, {
              images: arrayUnion(url),
            });
            counter++;
          }
        }

        if (!!project) {
          try {
            await updateDoc(projRef, {
              ...data,
              images: updateProjKeepIm ? project.images : newImages,
              filesUploaded: updateProjKeepIm
                ? project.filesUploaded
                : files.length,
            });
          } catch (e) {
            console.error(e);
          }
        }
        !files?.length && location.reload();
        if (counter >= files.length - 1) {
          close();

          location.reload();
        }
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "24px",
          borderBottom: "1px solid #b5b5b5",
        }}
      >
        <Box>
          <Typography fontSize="16" fontWeight="700">
            Új drónprojekt hozzáadása
          </Typography>
        </Box>
        <Box
          className="pointer"
          sx={{ position: "absolute", left: "24px" }}
          onClick={() => close()}
        >
          <ArrowBackIcon />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          px: "51px",
          pt: "32px",
        }}
      >
        {!!project &&
        !!project.images &&
        project.images.length !== 0 &&
        !modImages ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              gap: 1,
              mt: 2,
            }}
          >
            {project?.images?.map((image: any, index: number) => (
              <Box
                sx={{
                  width: 100,
                  height: 100,
                  background: `url('${image}') no-repeat`,
                  backgroundSize: "cover",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                borderRadius={2}
                key={index}
              >
                {!!project &&
                !!project.images &&
                project.images.length !== 0 ? (
                  // @ts-ignore
                  <Fab size="small" color="paper">
                    <LocalSeeIcon
                      onClick={() => {
                        setModImages(!modImages);
                      }}
                      className="pointer text-text"
                    />
                  </Fab>
                ) : (
                  <></>
                )}
              </Box>
            ))}
          </Box>
        ) : (
          <ProjectFilesUpload
            files={files}
            setFiles={setFiles}
            maxFileNum={maxImageNum}
          />
        )}

        <Box
          sx={{
            maxWidth: 600,
            width: "100%",
            mx: "auto",
          }}
        >
          <EditProjectForm
            handleSubmit={handleSubmit}
            loading={loading}
            errors={errors}
            handleSave={handleSave}
            register={register}
            control={control}
            project={project}
          />
        </Box>
      </Box>
    </>
  );
}
