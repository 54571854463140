import { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { FeedbackIcon } from "../FeedbackIcon";
import theme from "../../styles/theme";
import { dcContent } from "../../../services/content";
import * as PropTypes from "prop-types";
import RateReviewIcon from "@mui/icons-material/RateReview";

const maxFeedback = 5;

export function FeedbackItems({
  projectFeedback,
  feedback,
  setFeedback,
  sendRating,
}) {
  const [actNum, setActNum] = useState(null);

  useEffect(() => {
    setFeedback(actNum);
  }, [actNum, setFeedback]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      {!projectFeedback ? (
        <>
          {[...Array(maxFeedback)].map((e, i) => (
            <FeedbackIcon
              key={i}
              onClick={() => setActNum(i + 1)}
              color={
                i + 1 <= actNum
                  ? theme.palette.secondary.main
                  : theme.palette.textColors.main
              }
            />
          ))}
          <Button onClick={() => sendRating()}>
            {dcContent.buttonTexts.submitBtn}
          </Button>
        </>
      ) : (
        <Box>{dcContent.text.thankFeedback}</Box>
      )}
    </Box>
  );
}

FeedbackDisplay.propTypes = { feedback: PropTypes.any };

export function FeedbackDisplay({ feedback }) {
  return !!feedback ? (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2, my: 4 }}>
      <Typography>{dcContent.text.customerFeedback}:</Typography>
      <Box sx={{ display: "flex", gap: 1 }}>
        {[...Array(maxFeedback)].map((e, i) => (
          <FeedbackIcon
            key={i}
            color={
              i + 1 <= feedback
                ? theme.palette.secondary.main
                : theme.palette.textColors.main
            }
          />
        ))}
      </Box>
    </Box>
  ) : (
    <Box>
      <Typography fontWeight={600} textTransform="capitalize">
        {dcContent.text.feedback}:
      </Typography>
      <Box>{dcContent.text.noFeedback}</Box>
    </Box>
  );
}

export function AskForFeedback({
  clientEmail,
  setClientEmail,
  askForFeedback,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div>
        <RateReviewIcon fontSize="large" />
      </div>
      <Typography variant="h2" textAlign="center" mb={2}>
        {dcContent.text.askForFeedback}
      </Typography>
      <TextField
        type="email"
        placeholder="client email"
        value={clientEmail}
        onChange={(e) => setClientEmail(e.target.value)}
      />
      <div className="mt-large">
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={askForFeedback}
        >
          {dcContent.buttonTexts.sendBtn}
        </Button>
      </div>
    </Box>
  );
}
