export function FeedbackIcon({ color = null, onClick = null }) {
  return (
    <svg
      width="24"
      height="14"
      viewBox="0 0 24 14"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={!!onClick ? "pointer" : ""}
    >
      <path
        d="M24 1V0H17V1H20V2H19V4H15.7L14.199 1.4H9.855L8.355 4H5V2H4V1H7V0H0V1H3V2H2V7H5V6H8.167L8.383 6.374C7.0661 7.04178 5.95963 8.06091 5.18607 9.31859C4.41251 10.5763 4.00201 12.0235 4 13.5C4 13.6326 4.05268 13.7598 4.14645 13.8536C4.24021 13.9473 4.36739 14 4.5 14C4.63261 14 4.75979 13.9473 4.85355 13.8536C4.94732 13.7598 5 13.6326 5 13.5C5.00174 12.199 5.36598 10.9241 6.05186 9.81858C6.73773 8.71301 7.71811 7.82044 8.883 7.241L9.855 8.924H14.199L15.159 7.261C16.3129 7.84481 17.2826 8.73679 17.9606 9.83804C18.6386 10.9393 18.9984 12.2068 19 13.5C19 13.6326 19.0527 13.7598 19.1464 13.8536C19.2402 13.9473 19.3674 14 19.5 14C19.6326 14 19.7598 13.9473 19.8536 13.8536C19.9473 13.7598 20 13.6326 20 13.5C19.9983 12.0314 19.5924 10.5915 18.8269 9.33813C18.0614 8.08476 16.9658 7.0662 15.66 6.394L15.887 6H19V7H22V2H21V1H24Z"
        fill={color ? color : "#969696"}
      />
    </svg>
  );
}
