import Carousel from "react-material-ui-carousel";
import { Box } from "@mui/material";
import Image from "next/legacy/image";

export function ProjectCarousel({ project }) {
  // const [carouselIndex, setCarouselIndex] = useState(0);

  return project.images && project.images[0] ? (
    <Carousel
      index={0}
      autoPlay={false}
      className="project-carousel"
      fullHeightHover={false}
      swipe={true}
      IndicatorIcon={project.images.map((image, key) => (
        <Box
          key={key}
          className="project-carousel__smallImage"
          borderRadius={2.5}
        >
          <Image
            alt={project?.title}
            src={image}
            sizes="(min-width: 100vw) 100vw,
                  (min-width: 100vw) 100vw,
                 100vw"
            layout="fill"
            objectFit="cover"
            quality={50}
          />
        </Box>
      ))}
    >
      {project?.images?.map((item, i) => (
        <CarouselItem key={i} image={item} />
      ))}
    </Carousel>
  ) : (
    <Box
      sx={{
        width: "100%",
        height: 300,
        position: "relative",
        borderRadius: 2,
        overflow: "hidden",
      }}
    >
      <Image
        alt={project?.title}
        src={
          (project.images && project.images[0]) ??
          "/assets/project_placeholder.svg"
        }
        sizes="(min-width: 100vw) 100vw,
                  (min-width: 100vw) 100vw,
                 100vw"
        layout="fill"
        objectFit="cover"
        quality={50}
      />
    </Box>
  );
}

function CarouselItem({ image }) {
  return (
    <Box
      sx={{
        width: "100%",
        height: 300,
        position: "relative",
        overflow: "hidden",
      }}
      borderRadius={2.5}
    >
      <Image
        alt="project image"
        src={image}
        sizes="(min-width: 100vw) 100vw,
                  (min-width: 100vw) 100vw,
                 100vw"
        layout="fill"
        objectFit="cover"
        quality={50}
      />
    </Box>
  );
}
