import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

type Card = {
  title: string;
  content: string;
  imgUrl: string;
  left?: boolean;
  btnContent: string;
  dark?: boolean;
  content2?: string;
  imgHeight?: number;
  link: string;
};

const PromotionalCard = ({
  title,
  content,
  imgUrl,
  left = false,
  btnContent,
  dark = false,
  content2,
  imgHeight = 147,
  link,
}: Card) => {
  const router = useRouter();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 700) {
      setIsMobile(true);
    }
  }, []);

  return (
    <Box
      className={`d-grid align-items-center ${!dark && "bg-primary-lighten2"}`}
      sx={{
        borderRadius: "10px",
        boxShadow: "0 10px 20px rgba(0, 0, 0, .2)",
        gridTemplateColumns: left ? "max-content 1fr" : "1fr max-content",
        padding: { xs: "16px", sm: "16px 32px" },
        gap: { xs: "0", sm: "32px" },
      }}
    >
      {!isMobile && (
        <Box
          sx={{
            width: "300px",
            height: imgHeight,
            backgroundImage: `url(${imgUrl})`,
            gridColumn: left ? "1/2" : "2/3",
            backgroundSize: "cover",
            backgroundPosition: "center",
            overflow: "hidden",
            borderRadius: "10px",
          }}
        />
      )}

      <Box
        className="content mr-auto d-grid w-full"
        sx={{
          gridColumn: left ? "2/3" : "1/2",
          gridRow: "1/2",
          textAlign: { xs: !dark ? "center" : "unset", sm: "unset" },
          gap: { xs: "20px", sm: "16px" },
        }}
      >
        <Typography
          variant="h2"
          color={!dark ? "white" : ""}
          sx={{ margin: 0, fontSize: "28px" }}
          children={title}
        />
        <Typography
          variant="subtitle1"
          color={!dark ? "white" : ""}
          className="bold"
          children={content}
        />

        {content2 && (
          <Typography
            variant="body1"
            color={!dark ? "white" : ""}
            className="bold"
            children={content2}
          />
        )}
        <button
          data-variant="contained"
          className={`py-normal px-large ${
            !dark ? "bg-surface" : "bg-text text-surface"
          }`}
          style={{ justifySelf: isMobile ? "center" : "flex-start" }}
          onClick={() => link && router.push(link)}
          children={btnContent}
        />
      </Box>
    </Box>
  );
};

export default PromotionalCard;
