import { Box, Fab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import CheckIcon from "@mui/icons-material/Check";
import { compressImage } from "../../lib/global";
import { dcContent } from "../../../services/content";
import DeleteIcon from "@mui/icons-material/Delete";
import { useUserData } from "../../hooks/useUserData";
import Image from "next/legacy/image";

const fileTypes = ["jpeg", "png", "jpg", "GIF"];

export default function ProjectFilesUpload({ files, setFiles, maxFileNum }) {
  const [selected, setSelected] = useState(null);
  const { user, userData, dronerModel } = useUserData();

  const handleChange = async (newFiles) => {
    const temp = [];
    for (const f of newFiles) {
      const file = await compressImage(f);
      temp.push(file);
    }
    setFiles([...files, ...temp]);
  };

  useEffect(() => {
    setSelected();
  }, [files]);

  function handleRemove(item) {
    const temp = [];
    files.forEach((file, index) => {
      if (item === index) temp.push(null);
      else temp.push(file);
    });
    setFiles(temp.filter((n) => !!n));

    return undefined;
  }

  return (
    <Box>
      <FileUploader
        disabled={maxFileNum === 0 ? false : files.length >= maxFileNum}
        multiple
        hoverTitle=" "
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        maxSize={100}
        classes={""}
        children={
          <Box
            sx={{
              border: "1px solid  #4d4e4e",
              borderRadius: "10px",
              width: "100%",
              height: !!files.length ? 130 : 130,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              position: "relative",

              "&:hover": {
                background: !selected?.length ? "#fafafa" : "auto",
              },
            }}
          >
            {!files.length && <Box />}
            <>
              <Image
                src="/assets/cloudIcon.png"
                width={34}
                height={25}
                alt="cloud icon"
              />
              <Typography
                variant="caption"
                color="#4D4E4E"
                fontWeight="500"
                textAlign="center"
                sx={{
                  marginTop: "8px",
                  lineHeight: "15px",
                  width: "62px",
                  fontSize: "0.68rem",
                }}
              >
                Max. 1Mb
                <br />
                600x800px
              </Typography>
              {/* <Typography textAlign="center"> */}
              {/*   {dcContent.text.fileUpload.dragAndDrop}{' '} */}
              {/*   <span style={{color: 'red'}}> */}
              {/*     {' '} */}
              {/*     {dcContent.text.fileUpload.browse} */}
              {/*   </span> */}
              {/* </Typography> */}
              {/* <Typography>{dcContent.text.fileUpload.uploadMedia}</Typography> */}
              {/* <Typography> */}
              {/*   {files.length}/{maxFileNum === 0 ? 'unlimited' : maxFileNum} */}
              {/* </Typography> */}
              {/* <Typography style={{color: 'red'}}> */}
              {/*   {maxFileNum === 0 */}
              {/*     ? '' */}
              {/*     : files.length >= maxFileNum */}
              {/*       ? dcContent.errors.uploadLimit */}
              {/*       : ''} */}
              {/* </Typography> */}
            </>
          </Box>
        }
      />
      <Typography
        textAlign="center"
        fontSize="1rem"
        fontWeight="400"
        lineHeight="20px"
        mt="8px"
      >
        Add image here
      </Typography>
      <Box
        sx={{
          display: "flex",
          mt: 4,
          gap: "1rem",
          flexWrap: "wrap",
          justifyContent: "start",
        }}
      >
        {files.map((file, i) => (
          <SelectBox
            key={i}
            file={files.length >= i + 1 ? files[i] : null}
            index={i}
            selected={selected}
            setSelected={setSelected}
            handleRemove={handleRemove}
          />
        ))}
      </Box>
    </Box>
  );
}

function SelectBox({ file, index, selected, setSelected, handleRemove }) {
  function handleSelect() {
    if (!!file) {
      if (!selected) {
        setSelected(index);
      } else {
        setSelected(null);
      }
    }
  }

  const [hovered, setHovered] = useState(false);

  return (
    <Box
      sx={{
        width: 75,
        height: 66,
        borderRadius: "10px",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: file && `url(${URL.createObjectURL(file)})`,
        cursor: !!file && "pointer",
        position: "relative",
        justifyContent: "center",
        overflow: "hidden",
        "&:hover": file && {
          ...{
            background: `linear-gradient(0deg, rgba(36, 150, 204, 0.7), rgba(36, 150, 204, 0.7)), url(${URL.createObjectURL(
              file
            )})`,
            width: 75,
            height: 66,
            borderRadius: "10px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            transition: "all 0.5s ease",
          },
        },
      }}
      onMouseEnter={() => {
        // setSelected(index)
        setHovered(true);
      }}
      onMouseLeave={() => {
        // setSelected(index)
        setHovered(false);
      }}
    >
      {hovered && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
          }}
        >
          <DeleteIcon color="#inherit" onClick={() => handleRemove(index)} />
        </Box>
      )}
    </Box>
  );
}
