import { Box, Button, Modal, Typography } from "@mui/material";
import { db, functions } from "../../../api_config/firebase-configuration";
import { useContext, useEffect, useState } from "react";
import { arrayRemove, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { LocalizationContext, ShareLinkObject } from "../../lib/context";
import { useRouter } from "next/router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StarRateIcon from "@mui/icons-material/StarRate";
import {
  inspirationModalStyle,
  modalStyle,
  smallModalCard,
} from "../../styles/globalStyles";
import { AddProjectDetails } from "./AddProjectDetails";
import { dcContent } from "services/content";
import { AskForFeedback } from "../Sections/FeedbackItems";
import { ProjectCarousel } from "./ProjectCarousel";
import { useUserData } from "../../hooks/useUserData";
import HaveQuestions from "../HaveQuestions";

export default function ProjectContent({
  project,
  isFeedback = false,
  close,
}: {
  isFeedback?: boolean;
  project: any;
  close: any;
}) {
  const { localeStrings } = useContext(LocalizationContext);
  const { shareLinkObject, setShareLinkObject } = useContext(ShareLinkObject);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [clientEmail, setClientEmail] = useState("");
  const [feedback, setFeedback] = useState(project.feedback || null);
  const handleEditOpen = () => setOpenEdit(true);
  const handleFeedbackClose = () => setOpenFeedback(false);
  const handleEditClose = () => setOpenEdit(false);
  const { user, userData, actDroner } = useUserData();
  const router = useRouter();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const formFields = localeStrings.projectDetailsForm.formFields;

  useEffect(() => {
    setShareLinkObject({
      title: dcContent.text.shareTitle,
      url: encodeURI(
        `${location.origin}/droners/${project.username}?project=${project.id}`
      ),
    });
  }, [project.id, project.username, setShareLinkObject]);

  const handleProjectDelete = async () => {
    const userDocRef = doc(db, "users", actDroner.uid);
    const pubUserDocRef = doc(db, "usersPublic", actDroner.uid);
    try {
      await updateDoc(userDocRef, {
        projects: arrayRemove(project.id),
      });
      await updateDoc(pubUserDocRef, {
        projects: arrayRemove(project.id),
      });
      await deleteDoc(doc(db, "projects", project.id));
      const current = new URL(window.location.href);
      current.searchParams.delete("project");
      window.history.replaceState("", "", current);
      location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  const feedbackFun = httpsCallable(functions, "sendgrid-customerFeedback");
  const askForFeedback = () => {
    feedbackFun({
      username: project.username,
      clientName: project.clientName,
      projectId: project.id,
      clientEmail: clientEmail,
      email: userData.email,
      displayName: `${userData.fName} ${userData.lName}`,
    }).catch((err) => {
      console.error(err);
    });
    handleFeedbackClose();
    setClientEmail("");
  };

  return (
    <div
      className="project-popup"
      style={{ display: openEdit ? "none" : "block" }}
    >
      <div className="project-popup__header">
        <ArrowBackIcon onClick={close} className="pointer" />

        <h5>Drónprojekt</h5>
      </div>

      <div className="project-popup__main">
        <h3>{project.title}</h3>

        <ProjectCarousel project={project} />
        <div className="project-popup__items">
          {project?.feedback && (
            <div className="project-popup__item">
              <h5>Customer`s feedback</h5>
              <div className="project-popup__item--ratings">
                <StarRateIcon />
                <h6 className="subtitle-4">{project.feedback}</h6>
              </div>
            </div>
          )}

          <div className="project-popup__item">
            <h5>Project location</h5>
            <h6 className="body-1">{project.location}</h6>
          </div>

          <div className="project-popup__item">
            <h5>Project goal</h5>
            <h6 className="body-1">{project.description1}</h6>
          </div>
          <div className="project-popup__item">
            <h5>Challenges</h5>
            <h6 className="body-1">{project.description2}</h6>
          </div>
          <div className="project-popup__item">
            <h5>Skills</h5>
            <h6 className="body-1">{project.skills[0]}</h6>
          </div>
        </div>

        {/* {isFeedback ? (
          user && userData?.username === project.username ? (
            <Box>{dcContent.errors.cantRateSelf}</Box>
          ) : (
            <FeedbackItems
              projectFeedback={project.feedback}
              feedback={feedback}
              setFeedback={setFeedback}
              sendRating={() => {
                sendRating(project, clientEmail, feedback).then(() =>
                  router.reload()
                );
              }}
            />
          )
        ) : (
          <FeedbackDisplay feedback={project.feedback} />
        )} */}

        <HaveQuestions
          email={localeStrings?.errorPage?.mailToLink}
          title="Problémába ütköztél az oldalon? Kérdésed van? Vedd fel velünk a kapcsolatot!"
          text="Kapcsolat"
        />

        {project?.username === userData?.username && (
          <>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {project?.username === userData?.username &&
                !project.feedback && (
                  <Button
                    onClick={() => setOpenFeedback(!openFeedback)}
                    data-variant="primary-light"
                  >
                    {dcContent.buttonTexts.askForFeedbackBtn}
                  </Button>
                )}
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                <button
                  color="primary"
                  onClick={handleEditOpen}
                  data-variant="small-outlined"
                  data-color="text"
                >
                  {dcContent.buttonTexts.editBtn}
                </button>
                <Button
                  onClick={() => setDeleteModalOpen(true)}
                  variant="outlined"
                  size="small"
                  color="error"
                >
                  {dcContent.buttonTexts.deleteBtn}
                </Button>
              </Box>
            </Box>

            <Modal
              open={deleteModalOpen}
              onClose={() => setDeleteModalOpen(false)}
            >
              <Box sx={{ ...smallModalCard }}>
                <Typography variant="subtitle1" textAlign="center">
                  {dcContent.text.confirmDelete}
                </Typography>
                <Box sx={{ display: "flex", mt: 4 }}>
                  <Button variant="contained" onClick={handleProjectDelete}>
                    {dcContent.buttonTexts.deleteBtn}
                  </Button>
                  <Box sx={{ flexGrow: 1 }} />
                  <Button
                    // @ts-ignore
                    variant="cancel"
                    onClick={() => setDeleteModalOpen(false)}
                  >
                    {dcContent.buttonTexts.cancelBtn}
                  </Button>
                </Box>
              </Box>
            </Modal>

            <Modal open={openFeedback} onClose={() => setOpenFeedback(false)}>
              <Box sx={{ ...smallModalCard, minWidth: 300 }}>
                <AskForFeedback
                  clientEmail={clientEmail}
                  setClientEmail={setClientEmail}
                  askForFeedback={askForFeedback}
                />
              </Box>
            </Modal>

            <Modal open={openEdit} onClose={() => setOpenEdit(false)}>
              <Box
                // @ts-ignore
                sx={{ ...inspirationModalStyle }}
              >
                <AddProjectDetails
                  project={project}
                  close={() => handleEditClose()}
                />
              </Box>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
}
